import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { useRuntimeConfig } from '#imports';
import { getStorage } from 'firebase/storage';

export const useFirebase = () => {
  const config = useRuntimeConfig();

  const firebaseApp = initializeApp({
    apiKey: config.public.firebaseApiKey,
    authDomain: config.public.firebaseAuthDomain,
    databaseURL: config.public.firebaseDatabaseUrl,
    projectId: config.public.firebaseProjectId,
    storageBucket: config.public.firebaseStorageBucket,
    messagingSenderId: config.public.firebaseMessagingSenderId,
    appId: config.public.firebaseAppId,
    measurementId: config.public.firebaseMeasurementId,
  });
  const auth = getAuth();
  const firebaseStorage = getStorage(firebaseApp);

  return {
    firebaseApp,
    auth,
    firebaseStorage,
  };
};
